<template>
  <b-row>
    <b-col
      cols="12"
    >
      <b-card no-body>
        <b-card-header>
          <b-card-title>Seçilebilecek Teklifler</b-card-title>
        </b-card-header>
        <b-table
          v-if="projects.length > 0"
          responsive="sm"
          :fields="fields"
          :items="projects"
          striped
        >
          <template #cell(title)="data">
            {{ data.item.title }},
            <div v-if="data.item.onumber">
              <small class="text-primary">{{ data.item.onumber }}</small>
            </div>
          </template>
          <template #cell(customers)="data">
            {{ data.item.customers }}
            <div v-if="data.item.cnumber">
              <small class="text-primary">{{ data.item.cnumber }}</small>
            </div>
          </template>
          <template #cell(control)="data">
            <b-button
              size="sm"
              variant="success"
              :to="'/app/proforma-invoices/add/' + data.item.id"
              class="text-nowrap"
            >
              <FeatherIcon icon="PlusIcon" />
              Oluştur
            </b-button>
          </template>
        </b-table>
        <b-card-footer v-else>
          <b-alert show>
            <div class="alert-body text-center">
              <div class="mb-1">
                Faturalandırmaya uygun teklif bulunamadı!
              </div>
              <b-button
                variant="info"
                size="sm"
                :to="'/app/offers/add/' + this.$route.params.id_customers"
              >
                Teklif Oluştur
              </b-button>
            </div>
          </b-alert>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BTable, BAlert, BCardHeader, BCardTitle, BCardFooter,
} from 'bootstrap-vue'

export default {
  name: 'OffersSelect',
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    BAlert,
    BCardHeader,
    BCardTitle,
    BCardFooter,
  },
  data() {
    return {
      keyword: '',
      fields: [
        {
          key: 'title',
          label: 'Teklif',
        },
        {
          key: 'customers',
          label: 'Müşteri',
        },
        {
          key: 'control',
          label: '',
          tdClass: 'text-right',
        },
      ],
    }
  },
  computed: {
    projects() {
      return this.$store.getters['proformaInvoices/offerList']
    },
  },
  created() {
    this.getProjects()
  },
  methods: {
    getProjects() {
      const params = {
        select: [
          'offers.id AS id',
          'offers.title as title',
          'customers.company AS customers',
          'customers.cnumber AS cnumber',
          'CONCAT(customers.cnumber,"-O",offers.onumber) AS onumber',
        ],
        where: {
          'offers.id_offer_statuses': 4,
          'offers.id_customers': this.$route.params.id_customers,
        },
        start: 0,
        limit: 10,
      }
      this.$store.dispatch('proformaInvoices/getOfferList', params)
    },
  },
}
</script>
